<template>
    <div>
        <PageTitleHeader :pageAccessor="'attendeeHeader'" class="h-24 lg:h-56">
            <h1
                class="mb-0 title mg-title-header text-center mg-font-bold uppercase text-lg md:text-4xl xl:text-5xl"
            >
                {{ textHeader || "ATTENDEE SEARCH" }}
            </h1>
        </PageTitleHeader>

        <article class="container flex flex-col items-center">
            <div v-if="externalAttendeeList" class="w-full pt-12 md:pl-40">
                <a
                    class="text-xl font-semibold"
                    :href="externalAttendeeList"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Click here to view a sortable list of attendees.</a
                >
                <p>
                    Use the search field below to find a specific individual.
                    From the search results (or their profile page) click "Send
                    a Message" to communicate with an attendee.
                </p>
            </div>

            <div class="pt-6 px-4 w-full max-w-lg flex flex-col lg:flex-row">
                <attendee-search-form
                    class="flex flex-col my-4 lg:w-4/6"
                    :profileOwnerId="myOwnProfileId"
                ></attendee-search-form>

                <div
                    class="flex flex-col lg:ml-8 mt-4 lg:w-2/6 xl:pl-16 font-bold"
                >
                    <div class="field">
                        <div
                            aria-hidden="true"
                            class="hidden lg:block invisible label"
                        >
                            &#8203;
                        </div>
                        <b-button
                            tag="router-link"
                            :to="
                                `/attendees/attendee-profile/${myOwnProfileId}`
                            "
                            class="button pill-button is-primary w-full justify-center"
                            icon-left="user-circle"
                        >
                            View My Profile
                        </b-button>
                    </div>

                    <div
                        aria-hidden="true"
                        class="hidden lg:block invisible label"
                    >
                        &#8203;
                    </div>
                    <b-button
                        v-if="showFullList"
                        tag="router-link"
                        to="/attendees/list"
                        class="button pill-button w-full mb-8 justify-center"
                        icon-left="users"
                        >{{ fullListButtonText }}</b-button
                    >
                    <b-button
                        v-if="usesCreateActivityControl"
                        class="button pill-button w-full mb-8 justify-center"
                        icon-left="calendar"
                        tag="router-link"
                        to="/request-meeting"
                        >{{ buttonPlaceholder }}
                    </b-button>
                </div>
            </div>
            <!-- <div v-else>
                <Spinners />
            </div> -->
        </article>

        <AdSlot slot-name="attendees" />

        <div
            class="border-t border-dividers bg-attendee-swimlane text-center px-4 py-8"
        >
            <Spinners v-if="featuredLoading" />
            <div class="container">
                <AttendeeSwimLane
                    v-for="swimlane in swimlanesWithData"
                    :key="swimlane.key"
                    :header-label="swimlane.headerLabel"
                    :header-centered="swimlane.headerCentered"
                    :attendees="swimlane.data"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getModule } from "vuex-module-decorators";

import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";
import attendeeVuexModule from "@/store/vuex-modules/attendees";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import AttendeeSearchForm from "@/components/attendee-search/AttendeeSearchForm.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import AttendeeSwimLane from "@/components/attendees/swimlanes/AttendeesTaxonomySwimlane.vue";
import AdSlot from "@/components/shared/AdSlot.vue";

const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);
const layoutImagesStore = getModule(layoutImagesVuexModule);
const attendeeStore = getModule(attendeeVuexModule);

export default {
    components: {
        AttendeeSearchForm,
        PageTitleHeader,
        Spinners,
        AttendeeSwimLane,
        AdSlot
    },

    data() {
        return {
            isLoading: true,
            isOwnDataLoaded: false,
            id: ""
        };
    },
    /**
     * Computed
     */
    computed: {
        ...mapGetters(["awsConfig"]),
        pageOptions() {
            return this.$store.getters.getPageOptions("attendees");
        },

        externalAttendeeList() {
            return this.pageOptions.externalAttendeeList;
        },

        getLayoutOptions() {
            return this.$store.getters.layoutOptions;
        },

        userInfo() {
            return this.$store.getters.userInfo;
        },

        myOwnProfileId() {
            return this.userInfo.id;
        },

        layoutImagePagePrimary() {
            return layoutImagesStore.getLayoutImage("pagePrimary");
        },

        attendeeApiOptions() {
            // this.id = this.myOwnProfileId;
            const options = { id: this.myOwnProfileId, isDemoData: false };
            return options;
        },

        ownProfileInformation() {
            return attendeeStore.attendee;
        },

        textHeader() {
            return this.pageOptions.textHeader;
        },

        showFullList() {
            return this.pageOptions.section.fullAttendeeList;
        },

        fullListButtonText() {
            return this.pageOptions.content?.attendeeListLabel
                ? this.pageOptions.content.attendeeListLabel
                : "View Full List";
        },

        attendeeSwimlanes() {
            return this.pageOptions.swimLanes ? this.pageOptions.swimLanes : [];
        },

        swimlanesWithData() {
            return featuredAttendeeStore.featuredAttendeeSwimlanes;
        },

        featuredLoading() {
            return !featuredAttendeeStore.featuredAttendeesLoaded;
        },
        createActivityOptions() {
            const returnValue = this.$store.getters.getPageOptions(
                "createActivity"
            );

            return returnValue;
        },
        usesCreateActivityControl() {
            const optionChecker = this.$store.getters.isPageOptionActiveInEnv;
            return optionChecker("createActivity", "isActive");
        },

        buttonPlaceholder() {
            return this.createActivityOptions.textHeader
                ? this.createActivityOptions.textHeader
                : "Request a Meeting";
        }
    },

    /**
     * Lifecycle
     */
    created() {
        featuredAttendeeStore.getFeaturedAttendees(this.attendeeSwimlanes);
    }

    /**
     * Methods
     */
};
</script>
