import Vue from "vue";
import axios from "axios";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { AttendeeDataObject, GetAttendeeDataOptions } from "@/types/interfaces";
import Store from "../index";

// This module is only for functionality related to the following endpoint:
const endpoint = "attendees";

@Module({
    dynamic: true,
    store: Store,
    name: "AttendeesModule",
    namespaced: true
})
export default class AttendeesModule extends VuexModule {
    attendee: AttendeeDataObject = {};
    attendeeUpdatePayload: AttendeeDataObject = {};
    attendeeResetData: Partial<AttendeeDataObject> = {};

    avatarImage: File | string = "";
    avatarFileName = "";
    attendeeAvatarUrl = "";
    validFieldsExtraFields = [
        "myAvailability",
        "biography",
        "aboutOrganization"
    ];

    get bioExtraFields() {
        const pageOptions = this.context.rootGetters.getPageOptions(
            "attendees"
        );
        const profileExtraFields = pageOptions.profileExtraFields || [];

        return profileExtraFields.filter((item: string) =>
            this.validFieldsExtraFields.includes(item)
        );
    }

    get favoriteExhibitors() {
        return this.attendee.favoriteExhibitors ? this.attendee.favoriteExhibitors : []
    }

    @Mutation
    public setAttendeeAvatarImage(data: File | string) {
        this.avatarImage = data;
    }

    @Mutation
    public setAttendeeAvatarFileName(data: string) {
        this.avatarFileName = data;
    }

    @Mutation
    public setAttendeeAvatarUrl(data: string) {
        this.attendeeAvatarUrl = data;
    }

    @Mutation
    public setAttendee(data: AttendeeDataObject) {
        this.attendee = data;
    }

    @Mutation
    public setAttendeeUpdatePayload(data: AttendeeDataObject) {
        this.attendeeUpdatePayload = data;
    }

    @Mutation
    public setAttendeeResetData(data: Partial<AttendeeDataObject>) {
        this.attendeeResetData = data;
    }
   
    @Action({ commit: "setAttendee", rawError: true })
    getAttendee(options: GetAttendeeDataOptions) {
        const attendeeId = options.id;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}/${attendeeId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    this.context.commit("setAttendee", {});
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getAttendees(ids: Array<string> = []) {
        const token = this.context.rootGetters.idToken;

        const idString = ids.join(",");

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(endpoint, {
                    headers: {
                        Authorization: `bearer ${token}`
                    },
                    params: {
                        ids: idString
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getAttendeeObject(id: string) {
        const attendeeId = id;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}/${attendeeId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setAttendee", rawError: true })
    async putAttendee(options: GetAttendeeDataOptions) {
        const attendeeId = options.id;
        const token = this.context.rootGetters.idToken;
        const payload = this.attendeeUpdatePayload;

        this.validFieldsExtraFields.forEach((element) => {
            const x = element as keyof AttendeeDataObject;
            if (element in payload) {
                payload[x] = Vue.prototype.MgSanitize(payload[x]);
            }
        });

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`${endpoint}/${attendeeId}`, payload, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setAttendeeUpdatePayload({});
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    async putCompletedFirstLogin(attendeeId: string) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(
                    `${endpoint}/${attendeeId}`,
                    {
                        completedFirstLogin: true
                    },
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((response) => {
                    this.setAttendeeUpdatePayload({});
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    async uploadAttendeeAvatar(options: GetAttendeeDataOptions) {
        const attendeeId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = {
            data: { uploadURL: "", imageURLs: {} }
        };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `${endpoint}/${attendeeId}/avatar-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get Avatar put url!");
        }

        await this.context.dispatch("initAttendeeUpdatePayload");

        try {
            const updateValue = JSON.parse(
                JSON.stringify(this.attendeeUpdatePayload)
            );

            updateValue.images.avatar = putUrlResponse.data.imageURLs;
            this.context.commit("setAttendeeUpdatePayload", updateValue);

            const options = { id: attendeeId };
            await this.context.dispatch("putAttendee", options);
        } catch (error) {
            console.error(error, "Could not save attendee avatar");
        }

        try {
            const putResponse = await axios.request({
                url: putUrlResponse.data.uploadURL,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
            putResponse;
        } catch (error) {
            throw new Error("File upload failed.");
        }
    }

    @Action({ rawError: true })
    async initAttendeeUpdatePayload() {
        const attendeeData = JSON.parse(JSON.stringify(this.attendee));

        const attendeeShell = {
            prefix: "",
            title: "",
            email: "",
            phone: "",
            displayPhone: false,
            displayEmail: false,
            facebook: "",
            twitter: "",
            instagram: "",
            snapchat: "",
            youTube: "",
            linkedIn: "",
            biography: "",
            specialInterests: [],
            secondaryInterests: [],
            aboutOrganization: "",
            myAvailability: "",
            images: {
                avatar: {
                    "640x640": "",
                    "320x320": "",
                    "200x200": ""
                }
            },
            excludeFromSearch: false,
            excludeFromMessaging: false,
            excludeFromAppointments: false,
            isExhibiting: false,
            
        };

        const {
            displayPhone,
            displayEmail,
            email,
            phone,
            title,
            specialInterests,
            secondaryInterests,
            linkedIn,
            facebook,
            twitter,
            instagram,
            snapchat,
            youTube,
            myAvailability,
            biography,
            aboutOrganization,
            images,
            excludeFromSearch,
            excludeFromMessaging,
            excludeFromAppointments,
            isExhibiting,
            ...x
        } = attendeeData;

        // nothing to do with the following.
        // handling typescript warning about unused vars.
        x;

        let dataForReset = {
            displayPhone,
            displayEmail,
            email,
            phone,
            title,
            specialInterests,
            secondaryInterests,
            linkedIn,
            facebook,
            twitter,
            instagram,
            snapchat,
            youTube,
            myAvailability,
            biography,
            aboutOrganization,
            images,
            excludeFromSearch,
            excludeFromMessaging,
            excludeFromAppointments,
            isExhibiting
        };
        dataForReset = Vue.prototype.MgDefinedProps(dataForReset);

        // let dataForUpdate = JSON.parse(
        //     JSON.stringify(this.attendeeUpdatePayload)
        // );
        // dataForUpdate = Vue.prototype.MgDefinedProps(dataForUpdate);

        // if (0 === Object.keys(dataForUpdate).length) {
        //     dataForUpdate = dataForReset;
        // }

        const resetObject = { ...attendeeShell, ...dataForReset };
        const updateObject = { ...attendeeShell, ...dataForReset };

        this.context.commit("setAttendeeResetData", resetObject);
        this.context.commit("setAttendeeUpdatePayload", updateObject);
    }

    @Action({ rawError: true })
    public resetAttendeeUpdatePayload() {
        this.context.commit("setAttendeeUpdatePayload", {});
        this.initAttendeeUpdatePayload();
        this.context.commit("setAttendeeUpdatePayload", this.attendeeResetData);
    }

    @Action({ rawError: true })
    public clearAttendee() {
        this.context.commit("setAttendee", {});
    }
}
